





















import Vue from 'vue'
import { Component } from 'vue-property-decorator'
import { Action } from 'vuex-class'
import { CONFIRM_REGISTRATION } from '@/store/user/actions.type'
import { ConfirmRegistrationCredentials } from '@/store/user/types'
import { SimpleServerResponse } from '@/store/types'

@Component({
})
export default class ConfirmRegistration extends Vue {
  @Action(CONFIRM_REGISTRATION)
  public confirmRegistration: (params: ConfirmRegistrationCredentials) => Promise<SimpleServerResponse>

  private status = ''
  private showSpinner = true
  private success = false

  mounted (): void {
    const id = this.$route.params.id
    const nonce = this.$route.params.nonce

    this.confirmRegistration({ id: id, nonce: nonce }).then(data => {
      this.status = 'confirm-registration.' + data.message
      this.success = data.requestStatus !== 'FAILED'
    }, error => {
      this.status = 'confirm-registration.' + error.message
      if (error.status === 400) this.status = 'confirm-registration.check-link'
      this.success = false
    }).finally(() => {
      this.showSpinner = false
    })
  }
}
